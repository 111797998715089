<template>
  <WeContainer card="">
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-shopping-basket"></i>
        <span>Sipariş Raporları</span>
      </h5>
    </WeCard>
    <WeTableSearch
      v-on:search="updateOrderList"
      v-on:clear="onFilterClear"
      v-bind:show-icon="false"
      header-class="bg-white text-dark"
      header-text-class="text-dark h6"
      v-bind:show-on-load="true"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Tarih"
                placeholder="Tarih"
                v-bind:date-range="true"
                v-model="filter.dates"
                selected-shortcut="thisMonth"
              />
            </div>
            <!-- Created At -->

            <!-- Order Status -->
            <div class="d-block mb-3" v-if="marketplaceConfig">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="marketplace"
                label="Sipariş Kaynağı"
                select-label="title"
                placeholder="Sipariş Kaynağı"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-bind:option-prop="getMarketplaceList"
                v-model="filter.marketplaces"
              />
            </div>
            <!-- Order Status -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Order Status -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="order_status"
                label="Sipariş Durumu"
                placeholder="Sipariş Durumu"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-bind:option-prop="order.orderTypes"
                v-model="filter.order_status"
                v-bind:hint="orderStatusHint"
              />
            </div>
            <!-- Order Status -->

            <!-- Payment Type -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="payment_type"
                label="Ödeme Yöntemi"
                placeholder="Ödeme Yöntemi"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-bind:option-prop="order.paymentTypes"
                v-model="filter.payment_types"
              />
            </div>
            <!-- Payment Type -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeCard>
      <WeTable
        v-bind:index="false"
        v-bind:columns="columns"
        v-bind:data="list"
        v-bind:allowSortIndex="false"
        v-bind:fetch-on-mount="false"
        v-bind:actions="actions"
        v-bind:exportables="exportables"
        v-on:on-export="onExport"
        v-on:on-action="onAction"
        v-bind:ajax="true"
        v-bind:loading="loading"
        v-bind:table-result="tableResult"
        v-on:make-request="updateOrderList"
        total-column="total_amount"
        ref="orderTable"
      />
    </WeCard>
    <WeModal v-show="showModal" v-on:close="onClose">
      <div slot="body">
        <WeOrderDetail
          v-if="orderDetail && detailReady"
          v-bind:show-back="false"
          v-bind:order="orderDetail"
          v-bind:site-logo="siteLogo"
          v-bind:erp-config="erpConfig"
        />
        <div class="position-relative" style="min-height: 250px" v-else>
          <WeLoading />
        </div>
      </div>
    </WeModal>
  </WeContainer>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "List",
  data() {
    return {
      loading: true,
      showModal: false,
      tableTotalPrice: 0,
      columns: [
        { name: "created_at", th: "Sipariş Tarihi", type: "datetime" },
        { name: "order_no", th: "Sipariş No", type: "string" },
        {
          name: "source",
          th: "Sipariş Kaynağı",
          type: "string",
          image_width: 60,
        },
        {
          name: "products_count",
          th: "Ürün Adeti",
          type: "integer",
        },
        { name: "total_price", th: "Sipariş Tutarı", type: "currency" },
        { name: "total_discount", th: "İndirim", type: "currency" },
        { name: "total_cargo", th: "Kargo", type: "currency" },
        { name: "total_tax", th: "KDV", type: "currency" },
        { name: "total_amount", th: "Net Tutar", type: "currency" },
      ],
      actions: [
        {
          icon: "fas fa-eye",
          class: "btn-outline-indigo",
          action: "detail",
          tooltip: "Sipariş Detayı",
        },
      ],
      list: [],
      detail: null,
      detailReady: false,
      filter: {
        dates: {
          start: null,
          end: null,
        },
        order_status: [],
        payment_types: [],
        marketplaces: [],
      },
      exportables: ["excel", "pdf"],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("orderDetailModal", ["getById", "clearOrder"]),
    ...mapActions("order", ["getOrderTypes", "getPaymentTypes"]),
    ...mapActions("reports", ["makeSearch", "show"]),
    ...mapActions("tableExport", ["exportData"]),
    getFilter() {
      let copy = helper.clone(this.filter);

      if (!Object.keys(copy).length) {
        return;
      }

      copy.dates = [];

      if (
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        Object.entries(this.filter.dates).forEach(([key, value]) => {
          if (key !== "shortcut" && value) {
            copy.dates.push(value);
          }
        });
      } else {
        delete copy.dates;
      }

      return copy;
    },
    onSearch() {
      this.updateOrderList();
    },
    onAction(data) {
      if (data.key == "detail") {
        this.getOrderById(data.row.id);
      }
    },
    getOrderById(id) {
      this.detailReady = false;
      this.showModal = true;
      this.getById({
        id: id,
        onSuccess: (result) => {
          this.detail = result.data.item;
        },
        onFinish: () => {
          this.detailReady = true;
        },
      });
    },
    onClose() {
      this.showModal = false;
      this.clearOrder();
    },
    onFilterClear() {
      this.filter = {
        dates: {
          start: null,
          end: null,
        },
        order_status: [],
        payment_types: [],
        marketplaces: [],
      };
      this.updateOrderList();
    },
    onExport(data) {
      if (this.list && this.list.length) {
        let fileName = "Sipariş Raporları";

        if (
          this.filter.dates &&
          this.filter.dates.start &&
          this.filter.dates.end
        ) {
          let startDate = new Date(this.filter.dates.start).toLocaleDateString(
            "tr-TR"
          );
          let endDate = new Date(this.filter.dates.end).toLocaleDateString(
            "tr-TR"
          );

          fileName = startDate + " - " + endDate + " - " + fileName;
        }

        this.exportData({
          route: "report/orders/export",
          exportable: data,
          fileName: fileName,
          filter: {
            ...this.getFilter(),
            type: data.name,
          },
        });
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    updateOrderList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;

      this.makeSearch({
        routeKey: "orders",
        filter: this.getFilter(),
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result.data, this.tableResult, ["items", "status"]);
          this.list = result.data.items;
          this.tableTotalPrice = result.data.table_total;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.order,
      orderDetail: (state) => state.orderDetailModal,
      config: (state) => state.session.config,
      marketplace: (state) => state.marketplace,
    }),
    getFileName() {
      const defaultName = "Sipariş Raporları";
      let result = "";

      if (
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        let startDate = this.filter.dates.start;
        let endDate = this.filter.dates.end;

        startDate = new Date(startDate).toLocaleDateString("tr-TR");
        endDate = new Date(endDate).toLocaleDateString("tr-TR");

        result = `${startDate} - ${endDate} Sipariş Raporları`;
      } else {
        result = defaultName;
      }

      return result;
    },
    siteLogo() {
      return this.config["site.logo"] || null;
    },
    erpConfig() {
      return (
        this.config["site.erp_integration"] == "1" ||
        this.config["site.erp_integration"] == 1
      );
    },
    marketplaceConfig() {
      return (
        this.config["site.marketplace_integration"] == "1" ||
        this.config["site.marketplace_integration"] == 1
      );
    },
    orderStatusHint() {
      return `Varsayılan Sipariş Durumu
        <ul class="text-left mb-0">
          <li class="font-weight-bold">Ödeme Yapıldı</li>
          <li class="font-weight-bold">Onaylandı</li>
          <li class="font-weight-bold">Kargo Hazırlanıyor</li>
          <li class="font-weight-bold">Kargoya Verildi</li>
          <li class="font-weight-bold">Teslim Edildi</li>
        </ul>`;
    },
    getMarketplaceList() {
      let marketplaceList = this.marketplace.list;
      let eCommerce = {
        id: "e-commerce",
        title: "Pazaryerinde Olmayanlar",
      };

      const result = [eCommerce, ...marketplaceList];
      return result;
    },
  },
  mounted() {
    if (this.marketplaceConfig) {
      this.$store.dispatch("marketplace/getList");
    }
    this.getOrderTypes({
      onFinish: () => {
        this.getPaymentTypes({
          onFinish: () => {
            this.onSearch();
          },
        });
      },
    });
  },
};
</script>
